<template>
	<div class="mywz_publish width_1200 margin_auto justify_content_sb">
		<div class="mywz_publish_header">
			<div class="mywz_publish_header_title">文章管理</div>
			<div class="mywz_publish_header_acticon pointer" @click="gotoPubish()"
				>+ 发布文章</div
			>
		</div>

		<div class="mywz_publish_tips"
			>投稿审核不通过，请联系MetaEra小助手 微信: MetaEraGlobal</div
		>
		<div class="mywz_publish_line"></div>
		<div class="mywz_publish_status flex">
			<div class="mywz_publish_status_title flex">状态</div>
			<div class="mywz_publish_status_list flex">
				<div
					v-for="item in statusList"
					:key="item.value"
					:class="
						item.value === status
							? 'mywz_publish_status_item_active pointer'
							: 'mywz_publish_status_item pointer'
					"
					@click="changeStatus(item.value)"
				>
					{{ item.name }}
				</div>
			</div>
		</div>
		<div class="mywz_publish_line"></div>
		<div class="mywz_publish_category flex">
			<div class="mywz_publish_category_title flex">分类</div>
			<div class="mywz_publish_category_list flex">
				<div
					v-for="item in categoryList"
					:key="item.id"
					:class="
						item.id === categoryId
							? 'mywz_publish_category_item_active pointer'
							: 'mywz_publish_category_item pointer'
					"
					@click="changeCategory(item.id)"
				>
					{{
						localLanguage == 2
							? item.name_en
								? item.name_en
								: item.name
							: item.name
					}}
				</div>
			</div>
		</div>
		<div class="mywz_publish_line"></div>
		<div class="mywz_publish_list_header flex">
			<div class="mywz_publish_list_count flex" v-if="this.localLanguage != 2">
				<span>共</span>
				<span style="color: #0056ff">{{ mywzList.length }}</span>
				<span>篇文章</span>
			</div>
			<div class="mywz_publish_list_count flex" v-else>
				<span>A total of </span>
				<span style="color: #0056ff">&nbsp{{ mywzList.length }}&nbsp</span>
				<span>articles</span>
			</div>
		</div>
		<div v-if="mywzList.length > 0">
			<div v-for="(mywzItem, mywzIndex) in mywzList" :key="mywzIndex">
				<mywzItem
					:item="mywzItem"
					@editItem="editItem"
					@delItem="delItem"
				></mywzItem>
			</div>
		</div>
		<div v-if="hasData && mywzList.length == 0" class="mywz_publish_list_empty">
			<div class="mywz_publish_list_empty_icon">
				<img
					src="https://zy.metaera.media/assets/images/my/mywz_empty.png"
					alt=""
				/>
			</div>
			<div class="mywz_publish_list_empty_text maincolor">
				还没有发表文章，现在就去发表吧
			</div>
			<div class="mywz_publish_list_empty_action" @click="gotoPubish()">
				发表文章
			</div>
		</div>
	</div>
</template>

<script>
import { getCategoryList, delInformation } from "@/api/article";
import { getUserInformation } from "@/api/home";
import mywzItem from "./mywz_item.vue";
import { eventBus } from "../../../components/pc/leftTab.vue";
import { getUserId } from "@/utils/auth";
export default {
	name: "",
	components: {
		mywzItem,
	},
	data() {
		return {
			status: "-1",
			categoryId: "0",
			mywzList: [],
			hasData: false,
			statusList: [
				{ name: "全部", value: "-1" },
				{ name: "已通过", value: "1" },
				{ name: "待审核", value: "2" },
				{ name: "未通过", value: "3" },
				{ name: "草稿", value: "4" },
			],
			categoryList: [],
			localLanguage: 1,
		};
	},
	created() {
		let localLanguage = sessionStorage.getItem("localLanguage");
		if (localLanguage == "简体中文") {
			this.localLanguage = 0;
		} else if (localLanguage == "english") {
			this.localLanguage = 2;
		} else {
			this.localLanguage = 1;
		}
		this.getCategoryListFun();
		this.getUserInformation();
	},
	mounted() {
		eventBus.$on("global-notification", (data) => {
			let localLanguage = sessionStorage.getItem("localLanguage");
			if (localLanguage == "简体中文") {
				this.localLanguage = 0;
			} else if (localLanguage == "english") {
				this.localLanguage = 2;
			} else {
				this.localLanguage = 1;
			}
		});
		let localLanguage = sessionStorage.getItem("localLanguage");
		if (localLanguage == "简体中文") {
			this.localLanguage = 0;
		} else if (localLanguage == "english") {
			this.localLanguage = 2;
		} else {
			this.localLanguage = 1;
		}
	},
	methods: {
		editItem(id) {
			this.$router.push({
				path: "/publish",
				query: { id: id },
			});
		},
		delItem(id) {
			const that = this;
			delInformation({ id: id })
				.then((res) => {
					if (res.code == 200) {
						that.$message.success("删除成功");
						that.getUserInformation();
					} else {
					}
				})
				.catch((error) => {
					this.$message.error(error.message);
				});
		},
		gotoPubish() {
			this.$router.push({
				path: "/publish",
			});
		},
		changeStatus(value) {
			this.status = value;
			this.getUserInformation();
		},
		changeCategory(value) {
			this.categoryId = value;
			this.getUserInformation();
		},
		getCategoryListFun() {
			const that = this;
			getCategoryList({ size: 100, type: 5 })
				.then((res) => {
					if (res.code == 200) {
						that.categoryList = res.data;
						let allData = { id: "0", name: "全部" };
						this.categoryList.unshift(allData);
						that.categoryList.forEach((item) => {
							item.id = item.id.toString();
						});
					} else {
						that.categoryList = [];
					}
				})
				.catch((error) => {
					this.$message.error(error.message);
				});
		},
		getUserInformation() {
			getUserInformation({
				id: getUserId(),
				examine_type: this.status,
				category_id: this.categoryId,
			})
				.then((res) => {
					if (res.code == 200) {
						this.hasData = true;
						this.mywzList = res.data.list;
					}
				})
				.catch((error) => {
					// this.$message.error(error.message);
				});
		},
	},
};
</script>
<style scoped>
.mywz_publish {
	border-radius: 20px;
	opacity: 1;

	background: #ffffff;

	box-shadow: 6.43px 21.04px 35px 0px rgba(54, 62, 147, 0.051);
	width: 920px;
	padding-left: 30px;
	padding-right: 30px;
	padding-top: 30px;
	padding-bottom: 30px;
}
.mywz_publish_header_title {
	font-size: 20px;
	font-family: PingFang SC, "PingFang SC", "微软雅黑", "Helvetica Neue",
		"Hiragino Sans GB", "Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #333333;
	margin-top: 10px;
}
.mywz_publish_header_acticon {
	font-size: 16px;
	font-family: PingFang SC, "PingFang SC", "微软雅黑", "Helvetica Neue",
		"Hiragino Sans GB", "Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #0056ff;
	margin-top: -20px;
	text-align: right;
}

.mywz_publish_tips {
	font-size: 14px;
	font-family: PingFang SC, "PingFang SC", "微软雅黑", "Helvetica Neue",
		"Hiragino Sans GB", "Microsoft YaHei", Arial, sans-serif;
	font-weight: 500;
	color: #999999;
	margin-top: 20px;
	margin-bottom: 15px;
}
.mywz_publish_line {
	width: 100%;
	height: 1px;
	background: #dcdcdc;
	/* margin-top: 10px; */
}
.mywz_publish_status {
	height: 41px;
	align-items: center;
}
.mywz_publish_status_title,
.mywz_publish_category_title {
	font-size: 16px;
	font-family: PingFang SC, "PingFang SC", "微软雅黑", "Helvetica Neue",
		"Hiragino Sans GB", "Microsoft YaHei", Arial, sans-serif;
	font-weight: 400;
	color: #000000;
	width: 90px;
}

.mywz_publish_status_item,
.mywz_publish_category_item,
.mywz_publish_status_item_active,
.mywz_publish_category_item_active {
	font-size: 14px;
	font-family: PingFang SC, "PingFang SC", "微软雅黑", "Helvetica Neue",
		"Hiragino Sans GB", "Microsoft YaHei", Arial, sans-serif;
	font-weight: 400;
	color: #000000;
	margin-right: 30px;
}
.mywz_publish_status_item_active,
.mywz_publish_category_item_active {
	background: linear-gradient(
		45deg,
		#9945ff,
		#8752f3,
		#5497d5,
		#43b4ca,
		#28e0b9,
		#19fb9b
	);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}
.mywz_publish_category {
	height: 41px;
	align-items: center;
}
.mywz_publish_list_header {
	height: 41px;
	align-items: center;
	background: #f5f5f5;
}
.mywz_publish_list_count {
	align-items: center;
	margin-left: 16px;
}
.mywz_publish_list_count span {
	font-size: 16px;
	font-family: PingFang SC, "PingFang SC", "微软雅黑", "Helvetica Neue",
		"Hiragino Sans GB", "Microsoft YaHei", Arial, sans-serif;
	font-weight: 500;
	color: #000000;
}
.mywz_publish_list_empty {
	width: 100%;
	align-items: center;
	justify-content: center;
	display: flex;
	flex-direction: column;
}
.mywz_publish_list_empty_icon {
	width: 134px;
	height: 86px;
	margin-top: 64px;
	object-fit: cover;
}
.mywz_publish_list_empty_icon img {
	width: 100%;
	height: 100%;
}
.mywz_publish_list_empty_text {
	font-size: 14px;
	font-family: PingFang SC, "PingFang SC", "微软雅黑", "Helvetica Neue",
		"Hiragino Sans GB", "Microsoft YaHei", Arial, sans-serif;
	font-weight: 500;
	color: #0056ff;
	margin-top: 36px;
}
.mywz_publish_list_empty_action {
	background: linear-gradient(
		45deg,
		#9945ff,
		#8752f3,
		#5497d5,
		#43b4ca,
		#28e0b9,
		#19fb9b
	);
	border-radius: 6px 6px 6px 6px;
	opacity: 1;
	color: white;
	margin-top: 36px;
	width: 133px;
	height: 44px;
	align-items: center;
	justify-content: center;
	display: flex;
	flex-direction: row;
	cursor: pointer;
	font-size: 16px;
	font-family: PingFang SC, "PingFang SC", "微软雅黑", "Helvetica Neue",
		"Hiragino Sans GB", "Microsoft YaHei", Arial, sans-serif;
	font-weight: 500;
	align-items: center;
}
</style>
